.address {
  margin: 97px 0 100px;

  &__wrapper {
    display: flex;
    justify-content: space-between;

    & > *:last-child {
      flex-grow: 1;
      margin-right: 0;
      padding-left: 80px;
    }

    & > .address__showrooms--home:first-child {
      & > .address__title {
        max-width: 300px;
      }
    }

    & > .address__showrooms--home:last-child {
      padding: 53px 0 85px 73px;
    }

  }
  &__showrooms {
    padding: 60px 0 64px 70px;
    background: linear-gradient(268.43deg, #1B1919 83.32%, rgba(37, 37, 37, 0) 123.67%);
    box-shadow: inset 0 1px 30px rgba(219, 215, 215, 0.05);
    border-radius: 50px;
    margin-right: 30px;
    position: relative;

    &--home {
      padding: 54px 0 0 79px;
    }
  }


  &__title {
    @include SFProFont(45px, 700);
    margin: 0 0 20px 0;
    max-width: 424px;
  }

  &__subtitle {
    @include SFProFont(18px, 400);
    line-height: 24px;
    color: $color-gray;
  }

  &__td-subtitle {
    @include SFProFont(18px, 400);
    line-height: 24px;
    color: $color-gray;
    margin-bottom: 55px;
  }

  &__td-item {
    display: flex;
    align-items: center;
    margin-bottom: 31px;

    & > div {
      width: 30px;
      height: 30px;
    }

    & > span {
      @include SFProFont(18px, 400);
      line-height: 24px;
      margin-left: 22px;
      color: $color-gray;
    }
  }

  &__icon-wrapper-shop {
    margin: 127px 0 0 93px;
    max-width: 200px;
    max-height: 180px;
    position: relative;
  }

  &__icon-wrapper-home {
    margin: 40px 108px 109px 80px;
    max-width: 233px;
    max-height: 233px;
    position: relative;
  }

  &__icon-shop {
    width: 200px;
    height: 180px;
  }

  &__icon-home {
    width: 233px;
    height: 233px;
  }

  &__color {
    width: 134px;
    height: 134px;
    position: absolute;
    bottom: -70px;
    left: -80px;
    z-index: 1;

    &--home {
      width: 126px;
      height: 126px;
      bottom: -50px;
      left: -35px;
    }
  }

  &__info {
    margin-top: 32px;
  }

  &__item {
    margin-bottom: 38px;
  }

  &__name {
    @include SFProFont(22px, 700);
    margin-bottom: 15px;
  }

  &__desc {
    @include SFProFont(18px, 400);
    color: $color-gray;
    margin-bottom: 15px;
  }
  &__time {
    @include SFProFont(18px, 400);
    color: $color-gray;
  }
  &__contact {
    @include SFProFont(22px, 700);
  }

  &__call-icon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  &__phone {
    margin-top: 4px;
    color: $main-color;
    @include SFProFont(22px, 700);
    display: flex;
  }

  &__emotions {
    color: $color-gray;
    @include SFProFont(18px, 400);
    margin-bottom: 10px;
    line-height: 24px;
    max-width: 512px;
  }
}


@media (max-width: 1120px) {
  .address {
    &__title {
      font-size: 40px;
      max-width: 350px;
    }

    &__showrooms {
      padding-left: 50px;
    }

    &__icon-shop {
      width: 180px;
      height: 160px;
    }

    &__color {
      bottom: -80px;
    }

    &__name {
      font-size: 18px;
    }

    &__desc {
      font-size: 16px;
    }
    &__time {
      font-size: 16px;
    }
    &__contact {
      font-size: 18px;
    }
    &__phone {
      font-size: 18px;
    }

    &__icon-home {
      width: 200px ;
      height: 200px;
    }

    &__color--home {
      top: 130px;
      left: -40px;
    }

    &__td-subtitle {
      font-size: 16px;
    }

    &__td-item {
      & > span {
        font-size: 16px;
      }
    }

    &__wrapper {
      & > .address__showrooms--home:first-child {
        padding-left: 40px;
      }
      & > .address__showrooms--home:last-child {
        padding-left: 40px;
      }
    }

  }
}

@media (max-width: 930px) {
  .address {
    margin: 60px 0 70px;

    &__wrapper {
      flex-direction: column;

      & > *:last-child {
        padding-bottom: 56px;
        padding-left: 70px;
      }
    }

    &__showrooms {
      margin-right: 0;
      margin-bottom: 30px;
      padding: 58px 70px 125px 70px;
      border-radius: 20px;
    }

    &__icon-wrapper-shop {
      margin: 88px auto 0;
    }

    &__icon-wrapper-home {
      margin: 68px auto 0;
    }

    &__icon-shop {
      width: 194px;
      height: 173px;
    }

    &__icon-home {
      width: 194px;
      height: 173px;
    }

    &__title {
      max-width: none;
      font-size: 32px;
    }

    &__color {
      bottom: -65px;
      left: -65px;
      width: 129px;
      height: 130px;
    }

    &__td-title {
      margin-bottom: 19px;
    }

    &__subtitle {
      color: $color-native-gray;
    }

    &__td-subtitle {
      font-size: 18px;
      margin-bottom: 40px;

      & > br {
        display: none;
      }

       & > .address__br768 {
         display: block;
       }
    }

    &__emotions {
      max-width: 500px;
    }

    &__td-item {
      & > span {
        font-size: 18px;
      }
      margin-bottom: 25px;
    }

    &__color--home {
      width: 117px;
      height: 117px;
      top: 150px;
      left: -50px;
    }

    &__icon-wrapper-home {
      width: 215px;
      height: 215px;
      margin: 17px auto 0;
    }

    &__icon-home {
      width: 215px ;
      height: 215px;
    }

    &__wrapper {
      & > .address__showrooms--home:first-child {
        padding: 58px 70px 114px 70px;
        & > .address__title {
          max-width: none;
          margin-bottom: 16px;
        }
      }

      & > .address__showrooms--home:last-child {
        padding: 58px 0 84px 70px;
      }
    }

  }
}

@media (max-width: 600px) {
  .address {
    margin: 33px 0 39px;

    &__icon-wrapper-shop {
      margin: 84px auto 78px;
      width: 157px;
      height: 140px;
    }

    &__icon-shop {
      width: 157px;
      height: 140px;
    }

    &__color {
      bottom: -50px;
      left: -55px;
      width: 105px;
      height: 105px;
    }

    &__showrooms {
      padding: 37px 30px 45px;
      margin-bottom: 20px;
    }

    &__info {
      margin-top: 35px;
    }

    &__name {
      font-size: 16px;
      margin-bottom: 18px;
    }

    &__desc {
      margin-bottom: 18px;
    }
    &__time {
      font-size: 16px;
    }
    &__contact {
      font-size: 16px;
    }
    &__phone {
      margin-top: 21px;
      font-size: 16px;
    }

    &__call-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    &__td-subtitle {
      font-size: 16px;
      line-height: 20px;
    }

    &__td-item {
      & > span {
        font-size: 16px;
      }
    }


    &__icon-wrapper-home {
      width: 189px;
      height: 189px;
      margin: 76px auto 0;
    }

    &__icon-home {
      width: 189px ;
      height: 189px;
    }

    &__color--home {
      width: 102px;
      height: 101px;
      top: 130px;
      left: -28px;
    }


    &__subtitle {
      color: $color-gray;
    }

    &__wrapper {
      & > .address__showrooms--home:first-child {
        padding: 30px 30px 121px 30px;
        & > .address__title {
          //max-width: 200px;
        }
      }

      & > .address__showrooms--home:last-child {
        padding: 30px 20px 12px 30px;
      }

      & > *:last-child {
        padding-left: 30px;
        padding-bottom: 45px;
      }
    }
  }
}


@media (max-width: 470px) {
  .address {
    &__title {
      max-width: 250px;
    }

    &__wrapper {
      & > .address__showrooms--home:first-child {
        & > .address__title {
          max-width: 200px;
        }
      }
    }

    &__td-subtitle {
       & > .address__br768{
         display: none;
       }
    }

    &__td-item {
      & > span {
        max-width: 200px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 335px) {
  .address {
    &__title {
      font-size: 30px;
    }
    &__name {
      font-size: 15px;
    }
  }
}
