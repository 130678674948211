.error {
  &__section {
    //padding: 334px 0;
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }

  &__status {
    @include SFProFont(200px, 700);
    line-height: 200px;
    margin: 0 0 13px 0;
    color: $main-color;
  }

  &__name {
    @include SFProFont(60px, 700);
    line-height: 60px;
    margin: 0 0 50px 0;
  }

  &__subtitle {
    @include SFProFont(18px, 400);
    line-height: 23.4px;
    margin-bottom: 13px;
    text-align: center;
  }
  &__back {
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  .error {
    &__status {
      font-size: 150px;
      line-height: 150px;
    }
    &__name {
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 20px;
    }
    &__br768 {
      display: block;
    }
    &__subtitle {
      & > br {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .error {
    &__status {
      font-size: 90px;
      line-height: 90px;
      margin-bottom: 16px;
    }
    &__name {
      font-size: 25px;
      line-height: 32.5px;
      margin-bottom: 18px;
    }
    &__br768 {
      display: none;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom: 40px;
    }

    &__br375 {
      display: block;
    }
  }
}

@media (max-width: 335px) {
  .error {
    &__subtitle {
      font-size: 14px;
    }
  }
}
