.background-testdrive {
  background: url("../../../../public/image/TestDrive/backgrounds/testdrive-main-background.jpg");
  background-position: bottom 0px center;
  //background-size: 100% 115%;
  background-size: cover;
  overflow: visible;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background: linear-gradient(210.18deg, rgba(0, 0, 0, 0.6) 27.48%, rgba(0, 0, 0, 0) 78.11%);
  }

  &::before{
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0%), rgba(17, 17, 17, 35%), rgba(17, 17, 17, 100%));
    height: 150px;
    width: 100%;
    bottom: 0;
  }
}

.testdrive-container {
  //padding: 0 00px 0 0;
  max-width: 1272px;
  margin: 0 auto;
  overflow: visible;
}

.testdrive-main {
  margin-bottom: 150px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;

  &__img {
    //max-width: 786px;
    //max-height: 700px;
    //width: 768px;
    //height: 700px;
    position: absolute;
    left: -180px;
    z-index: 1;

    & > img {
      object-fit: cover;
    }
  }


  &__text {
    padding: 127px 0 225px;
  }

  &__title {
    margin: 0 0 20px 0;
    color: #D1D1D1;
    @include SFProFont(55px, 700);
    line-height: 60.5px;
  }

  &__subtitle {
    @include SFProFont(22px, 700);
    line-height: 28px;
    color: $color-native-gray;
  }

  &__focus {
    display: block;
  }

  &__blur {
    width: 40%;
    height: 60%;
    background: rgba(196, 196, 196, 1);
    filter: blur(120px);
    //border-radius: 50%;
    @include positionAllCenter(50%, 50%);
    z-index: -1;

  }
}

@media (max-width: 1500px) {
  .background-testdrive {
    background: url("../../../../public/image/TestDrive/backgrounds/testdrive-main-background-1440.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    &::after {
      display: none;
    }
  }
  .testdrive-main {
    &__img {
      //left: -100px;
      z-index: 1;
    }
    &__text {
      padding-right: 25px;
    }
  }
}

@media (max-width: 1300px) {
  .testdrive-main {
    &__title {
      font-size: 45px;
      line-height: 48.5px;
    }

    &__text {
      padding-right: 0;
    }
    &__subtitle {
      font-size: 20px;
      line-height: 24px;
      max-width: 500px;

      & > br {
        display: none;
      }
    }
  }
}


@media (max-width: 1024px) {
  .testdrive-main {
    margin-bottom: 102px;
    &__text {
      padding: 62px 0 360px;

    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 11px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      //max-width: 330px;
      max-width: 400px;
    }
    &__img {
      //max-width: 560px;
      //max-height: 504px;
      //bottom: -30px;
      left: -110px;
    }
  }

  .background-testdrive {
    background: url("../../../../public/image/TestDrive/backgrounds/testdrive-main-background.jpg");
    background-repeat: no-repeat;
    background-size: auto 105%;
    background-position: left -400px top -15px;
    &::after {
      display: block;
    }
  }
}


@media (max-width: 900px) {
  .testdrive-main {
    &__img {
      max-width: 560px;
      max-height: 504px;
      bottom: -20px;
      left: 0;
    }
    &__text {
      padding-right:  19px;
    }
    &__subtitle {
      max-width: 330px;
    }
  }
  .background-testdrive {
    background-size: auto 100%;
    background: url("../../../../public/image/TestDrive/backgrounds/testdrive-main-background-768.jpg");
    //background-position: left -365px top 0px;
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      //background: linear-gradient(206.05deg, rgba(0, 0, 0, 0.8) 8.44%, rgba(0, 0, 0, 0) 55.19%);
    }
  }
}

@media (max-width: 650px) {
  .background-testdrive::before{
    height: 310px;
  }
  .testdrive-main {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    &__text {
      padding: 30px 13px 0;
    }
    &__subtitle {
      max-width: 345px;
    }
    &__img {
      position: static;
    }
  }
  .background-testdrive {
    background-size: auto 83%;
    background-position: left -20px top -20px;
  }
  //.testdrive-container {
  //  padding: 0 13px;
  //}
}


@media (max-width: 400px) {
  .background-testdrive {
    //background-position: left -10px top -20px;
    background: url("../../../../public/image/TestDrive/backgrounds/testdrive-main-background-375jpg.jpg");
    background-repeat: no-repeat;
    background-size: auto 83%;
    &::after {
      display: none;
    }
  }
  .testdrive-main {
    &__img {
      max-height: 331px;
    }
  }
}

@media (max-width: 345px) {
  .testdrive-main {
    &__title {
      font-size: 28px;
      line-height: 32px;
    }
  }
}
