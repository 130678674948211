.designer {
  margin-top: 100px;
  border-radius: 50px;
  position: relative;
  background: linear-gradient(124.8deg, rgba(57, 69, 86, 0.7) 41.37%, rgba(57, 69, 86, 0) 56.41%);

  &::after {
    @include backgraundAfter();
    background:
    url("../../../../public/image/About/background/Ken-Okuyama-Design.jpg");
    background-repeat: no-repeat;
    background-position: right -35px bottom -75px;
    border-radius: 50px;
  }


  //height: 1200px;

  &__photo {
    display: none;
  }

  &__text {
    padding: 95px 0 371px 118px;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    @include SFProFont(55px, 700);
    margin: 0 0 20px 0;
    line-height: 60.5px;
  }

  &__position {
    @include SFProFont(22px, 700);
    margin: 0 0 50px 0;
    color: $main-color;
    line-height: 28px;
  }

  &__br {
    display: inline-block;
  }

  &__about {
    @include SFProFont(18px, 400);
    margin-bottom: 18px;
    max-width: 480px;
    line-height: 24px;
  }
}

@media (max-width: 1255px) {
  .designer {
    background-size: cover;
  }
}

@media (max-width: 1024px) {
  .designer {
    border-radius: 20px;
    background: linear-gradient(122.91deg, rgba(57, 69, 86, 0.7) 42.03%, rgba(57, 69, 86, 0) 64.16%);
    margin-top: 80px;

    &:after {
      border-radius: 20px;
      background: url("../../../../public/image/About/background/Ken-Okuyama-Design.jpg");
      background-repeat: no-repeat;
      background-size:  auto 642px;
    }

    &__text {
      padding: 55px 0 271px 70px;
    }

    &__about {
      max-width: 350px;
    }

    &__name {
      font-size: 32px;
      margin-bottom: 16px;
      line-height: 38.4px;
    }

    &__position {
      font-size: 14px;
      margin-bottom: 20px;
      line-height: 18.2px;
    }

    &__about {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 20px;
      & > br {
        display: none;
      }
    }
    &__br768 {
      display: block;
    }
  }
}

@media (max-width: 960px) {
  .designer {
    &:after {
      background-position: right -65px bottom -35px;
    }
  }
}


@media (max-width: 680px) {
  .designer {
    &__text {
      padding-left: 40px;
    }
  }
}

@media (max-width: 600px) {
  .designer {
    background: #111111;
    margin-top: 65px;

    &__br768 {
      display: inline;
    }

    &__text {
      padding: 0;
    }

    &__photo {
      width: 100%;
      display: block;
      border-radius: 20px;
      margin-bottom: 26px;


      & > img {
        border-radius: 20px;
        object-fit: cover;
      }
    }

    &__about {
      max-width: none;
    }
  }
}
