.home-features {

  &__swipe {
    height: 700px;
  }

  &__item {
    position: relative;
    margin: 100px 0 37px;
    transform: none !important;
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
  }

  &__info {
    max-width: 726px;
    max-height: 697px;
    margin: 0 auto;
    position: relative;
  }

  &__text {
    position: absolute;
    top: 12px;
    right: -126px;

    &--3 {
      top: 60px;
      right: -166px;
    }

    &--5 {
      top: 15px;
      right: -166px;
    }

    &--7 {
      top: 15px;
      right: -162px;
    }
  }

  &__img {
    max-width: 726px;
    max-height: 697px;
    margin: 0 auto;
  }

  &__modification {
    position: absolute;

    &--section-1 {
      top: 8%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 369px;
      max-height: 393px;
    }
  }

  &__name {
    @include SFProFont(22px, 700);
    line-height: 28px;
    color: $main-color;
    margin-bottom: 20px;
  }

  &__subtitle {
    @include SFProFont(16px, 400);
    line-height: 20.8px;
    max-width: 323px;
  }
}

@media (max-width: 1024px) {
  .home-features {
    &__title {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 164px;
    }

    &__item {
      margin: 65px 0 0;
    }

    &__name {
      margin-bottom: 10px;
      &--5 {
        margin-bottom: 12px;
      }
    }

    &__subtitle {
      font-size: 18px;
      line-height: 24px;
      max-width: 259px;

      &--3 {
        max-width: 330px;

        & > br {
          display: none;
        }
      }

      &--5 {
        max-width: 306px;

        & > br {
          display: none;
        }
      }

      &--7 {
        max-width: 330px;

        & > br {
          display: none;
        }
      }
    }

    &__br768 {
      display: block;
    }

    &__img {
      max-width: 683px;
      max-height: 632px;
    }

    &__text {
      top: -150px;
      right: 70px;

      &--3 {
        top: -110px;
        right: 0;
      }

      &--5 {
        top: -140px;
        right: 23px;
      }

      &--7 {
        top: -145px;
        right: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .home-features {
    &__item {
      margin: 47px 0 24px;
    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 175px;
      text-align: center;
    }
    &__text {
      text-align: center;
      top: -120px;
      left: 50%;
      width: 350px;
      transform: translateX(-50%);
    }
    &__name {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      &--3 {
        margin-bottom: 18px;
      }
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      width: 350px;
      margin: 0 auto;
      &--5 {
        width: 304px;
      }
    }
    &__br768 {
      display: none;
    }
    &__br375 {
      display: block;
    }
  }
}
