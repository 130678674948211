.testdrive-result {
  background-color: #2F2F2F;
  border-radius: 50px;

  padding: 65px 210px 65px 76px;
  margin-bottom: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {

  }

  &__title {
    @include SFProFont(45px, 700);
    line-height: 49.5px;
    margin-bottom: 25px;
  }
  &__subtitle {
    @include SFProFont(18px, 400);
    line-height: 24px;
    color: $color-gray;
  }
  &__ui {
    position: relative;
  }
  &__checkbox {
    width: 126px;
    height: 126px;
    background: #222222;
    box-shadow: 0 17px 23px rgba(10, 10, 10, 0.25), inset 0px -3px 8px rgba(7, 7, 7, 0.25), inset 6px 9px 9px rgba(70, 70, 70, 0.1), inset 0px 2px 2px rgba(117, 117, 117, 0.6), inset -10px -12px 18px rgba(0, 0, 0, 0.25), inset 3px 4px 8px rgba(89, 89, 89, 0.7);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  &__icon {
    width: 56px;
    height: 36px;
  }

  &__switch {
    width: 89px;
    display: flex;
    justify-content: flex-end;
    height: 45px;
    background: radial-gradient(92.09% 85.42% at 86.3% 87.5%, rgba(0, 0, 0, 0.23) 0%, rgba(0, 0, 0, 0) 86.18%), radial-gradient(65.28% 65.28% at 26.39% 20.83%, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.19) 69.79%, rgba(255, 255, 255, 0.32) 100%), #FFE8D2;
    box-shadow: 4px 38px 62px rgba(198, 198, 198, 0.33);
    border-radius: 109px;
    padding: 5px 7px 6px;

    position: absolute;
    top: -20px;
    right: -50px;
    z-index: 0;

  }
  &__circle {
    width: 33px;
    height: 33px;
    background: #BC8762;
    box-shadow: 4px 38px 62px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
}

@media (max-width: 1024px) {
  .testdrive-result {
    padding-right: 123px;
  }
}

@media (max-width: 930px) {
  .testdrive-result {
    padding: 46px 87px 47px 40px;
    border-radius: 20px;
    align-items: flex-start;
    margin-bottom: 80px;

    &__title {
      margin-bottom: 15px;
      font-size: 32px;
      line-height: 35.2px;
    }

    &__subtitle {
      & > br {
        display: none;
      }
    }

    &__checkbox {
      width: 102px;
      height: 102px;
    }

    &__ui {
      margin-top: 40px;
    }

    &__switch {
      width: 72px;
      height: 36px;
      padding: 5px 6px 5px 0;
      top: -15px;
      right: -35px;
    }

    &__circle {
      width: 27px;
      height: 27px;
    }

    &__icon {
      width: 53px;
      height: 39px;
    }

    &__br1024 {
      display: block;
    }
  }
}

@media (max-width: 730px) {
  .testdrive-result {
    padding-right: 60px;
    &__subtitle {
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .testdrive-result {
    flex-direction: column-reverse;
    //align-items: center;
    padding: 79px 24px 56px;
    margin-bottom: 60px;

    &__ui {
      margin: 0 auto 55px;
      width: 173px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__subtitle {
      font-size: 18px;
    }


    &__checkbox {
      width: 126px;
      height: 126px;
      align-items: flex-start;
      padding-top: 42px;
    }

    &__icon {
      width: 70px;
      height: 46px;
    }

    &__switch {
      width: 89px;
      height: 45px;
      padding: 5.69px 7px 6.21px 0;
      top: -15px;
      right: -5px;
    }

    &__circle {
      width: 33px;
      height: 33px;
    }
  }
}

@media (max-width: 450px) {
  .testdrive-result {
    &__subtitle {
      max-width: 280px;
    }
    &__br1024 {
      display: none;
    }
    &__br375 {
      display: block;
    }
  }
}
