.home-space {
  &__wrapper {
    padding: 75px 0 250px 64px;
    margin: 100px 0 59px;
    background: top center
    url("../../../../public/image/Home/HomeSpace.jpg") no-repeat;
    border-radius: 50px;
    background-position: left -190px top -80px;
    background-size: auto 138%;
    position: relative;
  }
  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin-bottom: 40px;
  }

  &__about {
    & > *:last-child {
      margin-bottom: 40px;
    }
  }

  &__shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(269.88deg, rgba(4, 3, 1, 0.85) 0.55%, rgba(4, 3, 1, 0) 21.72%);
  }

  &__content {
    position: relative;
  }

  &__subtitle {
    @include SFProFont(22px, 700);
    line-height: 28px;
    margin-bottom: 24px;
  }
  &__button {
    width: 275px;
    height: 50px;
  }
}


@media (max-width: 920px) {
  .home-space {
    &__wrapper {
      margin: 60px 0 64px;
      border-radius: 20px;
      padding-bottom: 114px;
      background-size: auto 120%;
      background-position: right -650px top -86px;
    }

    &__shadow {
      border-radius: 20px;
      background: linear-gradient(359.93deg, #111111 0.03%, rgba(4, 3, 1, 0) 29.58%),
      linear-gradient(179.8deg, rgba(4, 3, 1, 0.85) -0.96%, rgba(4, 3, 1, 0) 49.21%);
    }

    &__about {
      & > *:first-child {
        max-width: 400px;
      }
      & > *:last-child {
        max-width: 450px;
      }
    }

    &__subtitle {
      & > br {
        display: none;
      }
      & > span > br {
        display: none;
      }
    }

    &__br768 {
      display: block;
    }

    &__button {
      width: 282px;
    }
  }
}

@media (max-width: 600px) {
  .home-space {
    &__wrapper {
      margin: 39px 0 40px;
      padding: 27px 24px 47px;
      background-color: #000000;
      background-size: auto 485px;
      background-position: top center;
    }

    &__shadow {
      background: none;
     background: linear-gradient(358.43deg, #070707 18.65%, rgba(4, 3, 1, 0) 66.81%),
     linear-gradient(178.59deg, rgba(4, 3, 1, 0.85) 7.7%, rgba(4, 3, 1, 0) 30.67%);
    }

    &__title {
      font-size: 32px;
      line-height: 38.4px;
      text-align: center;
      margin-bottom: 207px;
    }

    &__about {
      *:last-child {
        margin-bottom: 30px;
      }
    }

    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
      max-width: 200px;
      & > span {
        display: inline;
      }
    }


    &__button {
      width: 224px;
      height: 42px;
      padding: 0;
      & > button {
      }
    }

    &__br375 {
      display: block !important;
    }
  }
}

@media (max-width: 375px) {
  .home-space {
    &__wrapper {
      background-position: left -240px top -30px;
    }
  }
}
