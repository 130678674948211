.success {
  &__wrapper {
    margin: 287px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__icon {
    width: 143px;
    height: 143px;
    margin-bottom: 42px;
  }
  &__title {
    @include SFProFont(40px, 700);
    line-height: 44px;
    margin-bottom: 22px;
  }
  &__subtitle {
    @include SFProFont(22px, 400);
    line-height: 28px;
    color: $color-gray;
  }
}

@media (max-width: 1024px) {
  .success {
    &__wrapper {
      margin: 196px 0 218px;
    }
    &__icon {
      margin-bottom: 45px ;
    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 20px;
    }
    &__subtitle {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@media (max-width: 500px) {
  .success {
    &__wrapper {
      margin: 144px 0 166px;
    }
    &__icon {
      width: 101px;
      height: 101px;
      margin-bottom: 32px ;
    }
    &__title {
      font-size: 20px;
      line-height: 20px;
    }
  }
}
