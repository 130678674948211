.background-home-designer {
  background: no-repeat url("../../../../public/image/Home/HomeHuman/Designer.jpg") #000000;
  background-position: bottom right;
  max-width: 1440px;
  margin: 0 auto;
}


.home-designer {
  background-color: #000000;

  &__text {
    padding: 219px 0 230px;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin: 0 0 69px 0;
  }

  &__role {
    @include SFProFont(22px, 700);
    line-height: 28px;
    margin-bottom: 38px;
  }

  &__subtitle {
    @include SFProFont(16px, 400);
    line-height: 20.8px;
    margin-bottom: 19px;
    max-width: 472px;

    &--last {
      max-width: 556px;
    }
  }
}

@media (max-width: 1350px) {
  .background-home-designer {
    background-position: bottom right -120px;
  }
  .home-designer {
    &__title {
      font-size: 45px;
      line-height: 50px;
    }

    &__role {
      font-size: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .background-home-designer {
    background-position: bottom -40px right -80px;
    background-size: auto 77%;
  }
  .home-designer {
    &__text {
      padding: 78px 0 320px;
    }

    &__title {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 50px;
    }

    &__role {
      font-size: 22px;
      margin-bottom: 30px;
      max-width: 560px;
    }

    &__subtitle {
      margin-bottom: 20px;
      max-width: 400px;

      &--last {
        max-width: 405px;

        & > br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .background-home-designer {
    background-size: auto 68%;
  }
  .home-designer {
    &__title {
      font-size: 42px;
    }

    &__role {
      font-size: 20px;
      max-width: 500px;
    }

    &__subtitle {
      font-size: 14px;
      max-width: 370px;
    }
  }
}

@media (max-width: 600px) {
  .background-home-designer {
    background-size: auto 53%;
    background-position: right bottom;
    max-width: 600px;
  }
  .home-designer {
    &__text {
      padding: 30px 0 416px;
    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 26px;
    }
    &__role {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 25px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 350px) {
  .background-home-designer {
    background-size: auto 50%;
    background-position: right -20px bottom;
  }
  .home-designer {
    &__title {
      font-size: 28px;
    }
    &__subtitle {
      font-size: 14px;
    }
  }
}
