.modal {
  position: relative;
  padding: 58px 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  &-send {
    padding: 105px 91px 114px;
  }

  &-mz {
    padding: 40px 43px 40px 45px;
    width: 100%;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 19px;
    height: 19px;
    cursor: pointer;
  }

  &__title {
    @include SFProFont(50px, 700);
    line-height: 55px;
    margin-bottom: 10px;
    color: $color-black;
    text-align: center;
  }

  &__subtitle {
    @include SFProFont(22px, 400);
    line-height: 28px;
    margin-bottom: 20px;
    text-align: center;
    color: $color-gray;
  }

  & > .form-any {
    &__data {
      width: 375px;

      & > .form-any__input {
        & > div > div.Mui-focused {
          border: 1px solid $color-black !important;
        }

        & > div > div > input {
          color: $color-black
        }
      }

      & > .form-any__info {
        & > .form-any__conditions {
          padding-top: 5px;

          & > a {
            color: #BC8762;
            opacity: 0.8
          }
        }
      }
    }
  }

  &__success {
    text-align: center;
  }

  &__icon {
    width: 143px;
    height: 143px;
    margin: 0 auto 33px;
  }

  &__success-ttl {
    @include SFProFont(36px, 700);

    color: $color-black;
    line-height: 39.6px;

    margin: 0 auto 10px;
  }
  &__success-subtitle {
    color: $color-gray;
    @include SFProFont(22px, 400);
    line-height: 28px;
    max-width: 444px;
    margin: 0 auto;
  }

  &__mz {
    width: 100%;
  }

  &__mz-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > *:last-child  {
     & > *:last-child {
       margin-bottom: 0;
     }
    }
  }

  &__mz-list {
    padding-left: 25px;
  }

  &__mz-title {
    @include SFProFont(22px, 700);
    line-height: 28px;
    color: $color-black;
    text-align: center;
    margin-bottom: 25px;
  }

  &__mz-name {
    @include SFProFont(16px, 700);
    line-height: 24px;
    color: $color-black;
  }
  &__mz-subtitle {
    list-style: disc;
    @include SFProFont(16px, 400);
    line-height: 33px;
    color: $color-gray;
    //margin-bottom: 5px;
  }
}

@media (max-height: 900px) {
  .modal {
    &__mz {
      &-subtitle {
        line-height: 28px;
      }
    }
  }
}


@media (max-height: 810px) {
  .modal {
    &__mz {
      &-subtitle {
        line-height: 25px;
      }
    }
  }
}




@media (max-width: 1024px) {
  .modal {
    &-mz {
      padding: 33px 0 56px 51px;
    }
    &__mz {
      &-title {
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 10px;
        text-align: left;
      }
      &-subtitle {
        font-size: 12px;
        line-height: 20px;
      }
      &-wrapper {
        flex-direction: column;
      }
      &-list {
        margin-bottom: 30px;
        padding-left: 20px;
      }
      &-name {
        line-height: 20px;
        margin-bottom: 2px;
      }
    }
  }
}


@media (max-width: 800px) {
  .modal {
    padding: 37px 42px 41px;

    &-send {
      padding: 72px 39px;
    }

    &__close {
      right: 15px;
      top: 15px;
      width: 14px;
      height: 14px;
    }

    &__title {
      font-size: 28px;
      line-height: 33.6px;
      margin-bottom: 6px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 18.2px;
    }

    & > .form-any {
      &__data {
        width: 252px;
        padding: 0;
        & > .form-any__info {
          & > .form-any__conditions {
            padding-top: 7px;

            & > a {
              color: #BC8762;
              opacity: 0.8
            }
          }
        }
      }
    }


    &__icon {
      width: 110px;
      height: 110px;
      margin-bottom: 18px;
    }

    &__success-ttl {
      @include SFProFont(28px, 700);
      color: $color-black;
      max-width: 271px;
      line-height: 33.6px;
      margin-bottom: 8px;
    }
    &__success-subtitle {
      color: $color-gray;
      @include SFProFont(14px, 400);
      line-height: 18.2px;
      max-width: none;
    }
  }
}

@media (max-width: 600px) {
  .modal {
    &-mz {
      padding: 16px 0 26px 16px;
    }
    &__mz {
      &-list {
        margin-bottom: 20px;
        & > *:nth-of-type(16) {
          max-width: 200px;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .modal {
    padding: 27px 25px 31px;

    &-send {
      padding: 52px 20px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 18.2px;
      max-width: 250px;
    }

    &__close {
      right: 10px;
      top: 5px;
    }
  }
}
