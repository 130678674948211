.background-about {
  background: #111111 url("../../../../public/image/About/background/main-background.jpg");
  background-position: right top;
  background-repeat: no-repeat;
  //background-size: auto 100%;
}


.about {
  margin-top: 104px;

  &__wrapper {
    display: flex;
    //justify-content: space-between;
    padding-left: 30px;
  }

  &__text {
    width: 600px;
    & > *:last-child {
      margin-bottom: 0;
    }
    flex-shrink: 0;
  }

  &__title {
    @include SFProFont(55px, 700);
    margin: 0 0 40px 0;
    line-height: 60.5px;
  }

  &__subtitle {
    @include SFProFont(22px, 700);
    line-height: 28px;
    margin-bottom: 10px;
    color: #D1D1D1;
  }

  &__img {
    width: 640px;
    height: 481px;
    margin-top: 123px;
    position: relative;
    margin-left: 30px;
    flex-shrink: 0;
  }
}

@media (min-width: 1921px) {
  .background-about {
    background-size: cover;
  }
}

@media (max-width: 1500px) {
  .about {
    margin-top: 102px;
    &__img {
      margin-top: 42px;
    }
  }
  .background-about {
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 93.25%, rgba(17, 17, 17, 0.5) 97.42%, #111111 100%), url("../../../../public/image/About/background/main-background.jpg");
    background-position: center top;
  }
}

@media (max-width: 1335px) {
  .about {
    &__wrapper {
      padding-left: 0;
    }
    &__img {
      width: auto;
      height: auto;
      max-width: 640px;
      max-height: 481px;
      margin-left: 20px;

      & > img {
        object-fit: contain;
      }
    }
  }
  .background-about {
    & > .more-container {
      max-width: 1272px;
      overflow: visible;
    }
  }
}

@media (max-width: 1279px) {
  .background-about {
    & > .more-container {
      overflow: hidden;
    }
  }
}


@media (max-width: 1024px) {
  .background-about {
    background-position: top left -395px;
  }
  .about {
    margin-top: 111px;
    &__text {
      position: relative;
      width: 340px;
      & > *:last-child {
        top: 115px;
        width: 420px;
      }
    }

    &__br {
      display: block;
    }

    &__title {
      font-size: 32px;
      margin-bottom: 20px;
      line-height: 38.4px;
    }

    &__subtitle {
      width: 440px;
      font-size: 16px;
      line-height: 20px;
      //position: absolute;
    }

    &__img {
      margin-top: 15px;
      margin-left: 1px;
      margin-bottom: 31px;
      flex-shrink: 0;
      width: 649px;
      height: 484px;
      max-width: none;
      max-height: none;
    }
  }
}

@media (max-width: 920px) {
  .background-about {
    & > .more-container {
      padding: 0 39px;
    }
  }
}

@media (max-width: 700px) {
  .background-about {
    position: relative;
    background: none;
    &::after {
      background: linear-gradient(180deg, rgba(17, 17, 17, 0) 81.25%, rgba(17, 17, 17, 0.5) 85.42%, #111111 100%), url("../../../../public/image/About/background/main-background.jpg");
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-position: top right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      filter: blur(2px);
    }
  }
  .about {
    &__text {
      width: 280px;
    }

    &__title {
      font-size: 30px;
    }

    &__br {
      display: inline;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 17px;
    }
  }
}


@media (max-width: 700px) {
  .background-about {
    & > .more-container {
      padding: 0 12px 0 11px;
    }
  }
  .about {
    margin-top: 121px;
    &__wrapper {
      flex-direction: column-reverse;
      align-items: center;
      position: relative;
      margin: 0 auto;

      & > .about__text {
        display: none;
      }
    }
    &__img {
      margin-top: 0;
      width: 100%;
      height: 100%;
    }

    &__text {
      margin-top: 59px;
      width: auto;
      & > *:last-child {
        width: auto;
      }
    }

    &__br {
      display: inline;
    }

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      width: auto;
      font-size: 14px;
      position: static;
      margin-right: 0;
      line-height: 18.2px;
    }
  }
}

@media (max-width: 400px) {
  .background-about {
    &::after {
      background-position: top left -700px;
      background-size: auto 438px;
    }
  }

  .about {
    &__br375 {
      display: block;
    }
  }
}


@media (max-width: 365px) {
  .about {
    &__br375 {
      display: none;
    }
    & > br {
      display: none;
    }
  }
}
