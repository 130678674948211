.massage {
  margin-top: 102px;
  display: flex;
  &__img {
    width: 760px;
    height: 728px;

    & > img {
      object-fit: contain;
    }
  }

  &__text {
    margin: 70px 0 0 44px;
  }

  &__title {
    @include SFProFont(55px, 700);
    margin-bottom: 40px;

  }
  &__subtitle {
    @include SFProFont(22px, 700);
    color: $color-gray;
    margin-bottom: 50px;
  }
}

.social {
  display: flex;
  cursor: pointer;

  & > *:last-child {
    margin-right: 0;
  }

  &-item {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
}

@media (max-width: 675px) {
  .social {
    &-item {
      width: 30px;
      height: 30px;
      margin-right: 15px;
      overflow: hidden;
    }
  }
}
