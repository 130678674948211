@keyframes hover {
  from { width: 0%; }
  to { width: 100%; }
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(130, 130, 130, 0.5);;
  padding: 26px 0 22px;
  position: relative;
  z-index: 2;

  &__logo {
    display: flex;
    align-items: center;

    &-icon {
      width: 88px;
      height: 52px;
    }
  }

  &__title {
    @include SFPUiFont(14px, 400);
    margin-left: 25px;
    color: #BDBDBD;
    margin-right: 5px;
  }

  &__nav-item {
    display: flex;

    & > *:last-child {
      margin-right: 0;
    }
  }

  &__item {
    @include SFPUiFont(16px, 400);
    color: $color-gray;
    text-transform: uppercase;
    margin-right: 35px;
    position: relative;

    &--disabled {
      transition: .2s all linear;
      &:hover {
        color: $color-white;
        &::after {
          animation: hover .2s linear;
          transition: .2s all linear;
          content: '';

          position: absolute;
          left: 50%;
          right: 50%;
          bottom: -40px;
          z-index: 1;

          transform: translate(-50%, -50%);

          width: 100%;
          height: 1px;
          background-color: $color-white;
        }
      }
    }

    &--active {
      color: #FFFFFF;

      &::after {
        content: '';

        position: absolute;
        left: 0;
        bottom: -40px;
        z-index: 1;

        width: 100%;
        height: 1px;
        background-color: $color-white;
      }
    }
  }

  &__phone-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__phone {
    color: $main-color;
    @include SFPUiFont(18px, 700);

    &-subtitle {
      margin-top: 4px;
      @include SFPUiFont(12px, 400);
      color: #BDBDBD;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__icon {
    background-color: rgba(0, 0, 0, 0);
    margin-left: 24px;
    cursor: pointer;
  }
}

@media (max-width: 1100px) {
  .header {
    &__title {
      margin-left: 15px;
      max-width: 150px;
    }

    &__item {
      font-size: 14px;
    }
  }
}


@media (max-width: 1024px) {
  .header {
    border-bottom: 0;

    &__nav {
      display: none;
    }

    &__title {
      margin-left: 25px;
      max-width: none;
    }

    &__item {
      font-size: 14px;
    }

    &__logo {
      &-icon {
        height: 56px;
        width: 94px;
      }
    }

    &__drawer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;

      &-wrapper {
        padding: 26px 39px;
      }

      &-title {
        @include SFPUiFont(16px, 400);
        margin-left: 25px;
        color: #BDBDBD;
      }

      &-close {
        cursor: pointer;
        width: 33px;
        height: 33px;
        //padding: 10px;
      }

      &-innerWrapper {
        padding-left: 79px;
      }

      &-list {
        margin-bottom: 94px;

        & > *:last-child {
          margin-bottom: 0px;
        }
      }

      &-item {
        @include SFProFont(40px, 700);
        margin-bottom: 40px;
      }

      &-number {
        @include SFProFont(35px, 700);
        margin-bottom: 1px;
      }

      &-order {
        @include SFProFont(18px, 400);
        color: $color-gray;
        margin-bottom: 30px;
      }

      &-button {
        width: 219px;
        height: 49px;
        margin-bottom: 40px;
      }
    }


    &__drawer-innerWrapper {
      & > .social {
        & > * {
          width: 40px;
          height: 40px;
          margin-right: 35px;
        }
      }

    }
  }
}

@media (max-width: 600px) {
  .header {
    border-bottom: 0;

    &__phone-block {
      display: none;
    }

    &__nav {
      display: none;
    }

    &__title {
      font-size: 12px;
    }

    &__item {
      font-size: 14px;
    }

    &__logo {
      &-icon {
        height: 32px;
        width: 54px;
      }
    }

    &__drawer {
      margin-bottom: 50px;


      &-wrapper {
        padding: 20px 13px;
      }

      &-title {
        font-size: 12px;
      }

      &-close {
        width: 24px;
        height: 24px;
      }

      &-innerWrapper {
        padding-left: 56px;
      }

      &-list {
        margin-bottom: 33px;
        & > *:last-child {
          margin-bottom: 0;
        }
      }

      &-item {
        font-size: 30px;
        margin-bottom: 30px;
      }

      &-number {
        font-size: 20px;
        margin-bottom: 1px;
      }

      &-order {
        font-size: 14px;
        margin-bottom: 20px;
      }

      &-button {
        margin-bottom: 49px;
      }
    }

    &__drawer-innerWrapper {
      & > .social {
        & > * {
          margin-right: 27px;
        }
      }
    }
  }
}


@media (max-width: 360px) {
  .header {
    &__drawer {
      margin-bottom: 30px;

      &-list {
        margin-bottom: 20px;
      }

      &-innerWrapper {
        padding-left: 30px;
      }

      &-item {
        @include SFProFont(22px, 700);
        margin-bottom: 30px;
      }
    }
  }
}
