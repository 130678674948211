.testdrive-switch {
  margin: 100px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    @include SFProFont(55px, 700);
    line-height: 61px;
    margin-bottom: 51px;
    max-width: 760px;
    text-align: center;
  }
  &__subtitles {
    position: relative;
    border-radius: 50px;
    background-color: #222222;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    transition: .2s all linear;

    & > *:last-child {
      margin-right: 0;
    }
  }


  &__item {
    @include SFProFont(18px, 400);
    line-height: 24px;
    background-color: #222222;
    border-radius: 50px;
    margin-right: 28px;
    padding: 15px 52px;
    cursor: pointer;
    position: relative;

    &--active {
      background-color: $main-color;
    }
  }

  &__fixed {
    position: fixed;
    top: 10px;
    z-index: 100;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.34);
    transition: .2s all linear;

    padding: 0 13px;
    height: 50px;
    align-items: center;

    & > * {
      font-size: 14px;
      padding: 10px 30px;
      line-height: 14px;
      transition: .2s all linear;
    }
  }
}



@media (max-width: 960px) {
  .testdrive-switch {
    margin: 80px 0 60px;
    &__title {
      font-size: 32px;
      line-height: 39px;
      margin-bottom: 34px;
      max-width: 450px;
    }
    &__subtitles {
      padding: 8px;
    }
    &__item {
      font-size: 16px;
      line-height: 20px;
      padding: 10px 32px;
      margin-right: 30px;
    }
  }
}

@media (max-width: 500px) {
  .testdrive-switch {
    margin: 60px 0 50px;
    .testdrive-switch {
      &__title {
        margin-bottom: 31px;
        max-width: 300px;
      }
      &__item {
        font-size: 14px;
        padding: 10px 21px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 360px) {
  .testdrive-switch {
    .testdrive-switch {
      &__title {
        font-size: 30px;
      }
      &__item {
        font-size: 12px;
        padding: 8px 21px;
        margin-right: 5px;
      }
    }
  }
}
