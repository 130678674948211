.home-slider {
  margin: 158px 0 197px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__mobile-text {
    display: none;
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin-bottom: 57px;
    color: #000000;
  }

  &__container {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
  }

  &__item {
    display: flex;
  }

  &__info {
    width: 636px;
    height: 522px;
    margin-right: 15px;
    border-radius: 20px;
    display: flex;
    position: relative;

    &--white {
      background: linear-gradient(236.73deg, #D9CBB6 26.09%, #EEE6DB 65.85%);;
    }
    &--black {
      background: linear-gradient(234deg, #272727 21.69%, #6A6A6A 117%), linear-gradient(236.73deg, #D9CBB6 26.09%, #EEE6DB 65.85%);
    }
    &--orange {
      background: linear-gradient(234deg, #D88531 21.69%, #FFE8CF 117%);
    }
    &--brown {
      background: linear-gradient(234deg, #4F3229 21.69%, #C49A8B 117%);;
    }
  }

  &__info--white {
    & > .home-slider__text {
      top: 61px;
      right: 32px;
      & > .home-slider__name {
        line-height: 45.5px;
        margin-bottom: 14px;
      }
    }
  }

  &__text {
    position: absolute;
    right: 28px;
    top: 72px;
    z-index: 1000;
  }

  &__chair {
    padding-top: 36px;
    & > img {
      border-radius: 0 0 0 20px;
    }
  }

  &__name {
    @include SFProFont(35px, 700);
    line-height: 38.5px;
    margin-bottom: 20px;
  }

  &__model {
    @include SFProFont(22px, 400);
    line-height: 28px;
    margin-bottom: 20px;
  }

  &__button {
    width: 268px;
    height: 50px;
    @include SFProFont(20px, 700);
    line-height: 18px;
    border: 2px solid #FFFFFF;
    cursor: pointer;
    border-radius:  42px;
    background-color: rgba(0, 0, 0, 0);
    color: $color-white;
    transition: .2s all linear;
    &:hover {
      background-color: #FFFFFF;
    }
    &--1 {
      &:hover {
        color: #DFD3C0
      }
    }
    &--2 {
      &:hover {
        color: #DC9143
      }
    }
    &--3 {
      &:hover {
        color: #64453B
      }
    }
    &--4 {
      &:hover {
        color: #2F2E2E
      }
    }
  }

  &__background {
    flex-grow: 1;
    border-radius: 20px;
    background: url("../../../../public/image/Home/HomeSlider/HomeSliderBackground.jpg");
    background-position: left top;
  }

  &__img {
    padding-top: 79px;
    max-width: 410px;
    max-height: 431px;
  }


  &__colors {
    display: flex;
    align-items: center;

    & > * {
      background-color: rgba(0, 0, 0, 0)
    }

    & > *:nth-of-type(2) {
      margin-left: 25px;
    }

  }

  &__color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 25px;
    cursor: pointer;

    &--active {
      position: relative;
      &::after {
        transition: .2s all linear;
        content: '';
        position: absolute;
        width: 44px;
        height: 44px;
        border: 1px solid #828282;
        border-radius: 50%;
        @include positionAllCenter(50%, 50%)
      }
    }
  }

  &__prev, &__next {
    width: 11.18px;
    height: 22.22px;
    cursor: pointer;
  }

  &__prev {
    transform: rotate(180deg);
  }
}

@media (max-width: 1100px) {
  .home-slider {
    &__text {
      top: 52px;
      right: 15px;
    }
  }
}


@media (max-width: 1024px) {
  .home-slider {
    margin: 28px 0 27px;

    &__info {
      display: none;
    }

    &__title {
      display: none;
    }

    &__mobile-text {
      display: block;
    }

    &__img {
      padding-top: 0;
      margin-top: 0;

      width: 674px;
      height: 709px;
      max-width: none;
      max-height: none;

      position: absolute;
      bottom: -25px;
      left:  -30px;
    }


    &__text {
      right: 50px;
      top: 50px;
    }

    &__name {
      font-size: 45px;
      line-height: 58.5px;
      margin-bottom: 15px;
    }

    &__button {
      width: 258px;
      height: 50px;
      font-size: 16px;
      line-height: 20px;
    }

    &__background {
      position: relative;
      height: 900px;
      &--white {
        background: linear-gradient(198.71deg, #D9CBB6 15.66%, #EEE6DB 64.96%);
      }
      &--black {
        background: linear-gradient(234deg, #272727 21.69%, #6A6A6A 117%), linear-gradient(198.71deg, #D9CBB6 15.66%, #EEE6DB 64.96%);
      }
      &--orange {
        background: linear-gradient(234deg, #D88531 21.69%, #FFE8CF 117%);
      }
      &--brown {
        background: linear-gradient(232.27deg, #4A2E25 15.13%, #C49A8B 109.47%);
      }
    }

    &__container {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 800px) {
  .home-slider {
    &__img {
      width: 674px;
      height: 689px;
      left: -40px;
    }
  }
}


@media (max-width: 700px) {
  .home-slider {
    margin: 26px 0 50px;
    &__text {
      position: static;
      padding: 27px 30px 0;
    }
    &__name {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 15px;
    }
    &__model {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 18px;
    }
    &__img {
      width: 375px;
      height: 379px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -40px;
    }
    &__background {
      height: 547px;
      &--black {
        background:linear-gradient(234deg, #272727 21.69%, #6A6A6A 117%);
      }
      &--orange {
        background: linear-gradient(234deg, #D88531 21.69%, #FFE8CF 117%), linear-gradient(190.48deg, #9B6236 33.55%, #FFE8CF 101.37%), linear-gradient(234deg, #272727 21.69%, #6A6A6A 117%);
      }
      &--brown {
        background: linear-gradient(232.27deg, #4A2E25 15.13%, #C49A8B 109.47%);
      }
    }
    &__container {
      margin-bottom: 0;
      & > .swiper {
        //overflow: visible;
        overflow-y: visible;
        padding-bottom: 40px;
      }
    }
  }
}

@media (max-width: 400px) {
  .home-slider {
    &__img {
      left: 39%;
      transform: translateX(-40%);
    }
  }
}


@media (max-width: 370px) {
  .home-slider {
    &__background {
      height: 507px;
    }
    &__name {
      font-size: 26px;
      line-height: 28px;
    }
    &__img {
      width: 351px;
      height: 359px;
      bottom: -35px;
      left: -15px;
      transform: translateX(0);
    }
  }
}

@media (max-width: 350px) {
  .home-slider {
    &__img {
      left: -25px;
    }
    &__text {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}


@media (max-width: 330px) {
  .home-slider {
    &__img {
      width: 341px;
      height: 349px;
      left: -30px;
    }
  }
}
