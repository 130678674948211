.about-events {
  margin-top: 100px;
  border-top: 1px solid rgba(130, 130, 130, 0.5);

  &__br {
    display: block;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0;
    border-bottom: 1px solid rgba(130, 130, 130, 0.5);
  }

  & > *:last-child {
    padding-bottom: 101px;
    padding-right: 136px;

    & > figure {
      margin-right: 30px;
      max-height: 473px;
    }

    & > div > p {
      margin-right: 0;
      max-width: 500px;
    }
  }

  &__logo {
    width: 182px;
    height: 64px;
    margin-bottom: 30px;
  }

  &__text {
    @include SFProFont(22px, 700);
    color: $color-gray;
    width: 580px;
    line-height: 28px;
    margin-right: 30px;

  }

  &__exhibition-img {
    max-width: 500px;

    & > img {
      border-radius: 20px;
      //margin-bottom: 17px;
      object-fit: contain;
    }
  }

  &__presentation-img {
    width: 500px;
    height: 473px;
    margin-right: 136px;

    & > img {
      border-radius: 20px;
      margin-bottom: 17px;
    }
  }


  &__img-name {
    margin-top: 17px;
    color: $main-color;
    @include SFProFont(18px, 400);
  }
}


@media (max-width: 1024px) {
  .about-events {
    border: 0;

    & > *:first-child {
      padding-top: 0;
    }

    &__img-name {
      margin-top: 16px;
    }

    &__br {
      display: inline;
    }

    &__wrapper {
      align-items: flex-start;
      border: 0;
      padding-bottom: 45px;
    }


    & > *:last-child {
      padding-top: 0;
      align-items: center;
      flex-direction: row-reverse;
      padding-bottom: 0;
      padding-right: 0;

      & > figure {
        margin-right: 0;
        max-height: none;
      }

      & > p {
        margin-right: 30px;
        width: 450px;
        min-width: 450px;
      }
    }

    &__logo {
      margin-bottom: 40px;
    }

    &__text {
      font-size: 20px;
      line-height: 18.2px;
      width: 450px;
    }

    &__exhibition-img {
      max-width: 400px;
    }
  }
}


@media (max-width: 900px) {
  .about-events {
    &__text {
      font-size: 16px;
      width: 330px;
    }

    & > *:last-child {
      & > p {
        width: 320px;
        min-width: 330px;
      }
    }

    &__img-name {
      font-size: 16px;
    }

    &__exhibition-img {
      width: 330px;
      height: 286px;
      & > img {
        max-width: 330px;
        max-height: 326px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 800px) {
  .about-events {
    &__exhibition-img {
      min-width: 330px;
      min-height: 286px;
    }
  }
}

@media (max-width: 768px) {
  .about-events {

    & > *:last-child {
      & > div > p {
        margin-right: 30px;
      }
    }

    &__text {
      font-size: 14px;
      width: 303px;
    }

    & > *:last-child {
      & > p {
        min-width: 300px;
      }
    }
  }
}

@media (max-width: 750px) {
  .about-events {
    &__exhibition-img {
      min-width: auto;
      min-height: auto;
      width: auto;
      height: auto;
    }
    &__img-name {
      font-size: 14px;
    }
  }
}

@media (max-width: 620px) {
  .about-events {
    &__text {
      width: 260px;
    }

    & > *:last-child {
      & > p {
        width: 260px;
        min-width: 260px;
      }
    }
  }
}

@media (max-width: 560px) {
  .about-events {
    &__text {
      font-size: 12px;
    }
    &__img-name {
      font-size: 12px;
    }
  }
}


@media (max-width: 500px) {
  .about-events {
    margin-top: 60px;
    border-top: 1px solid rgba(130, 130, 130, 0.5);

    & > *:first-child {
      padding-top: 30px;
    }

    &__exhibition-img {
      & > img {
        max-width: none;
        max-height: none;
      }
    }

    &__wrapper {
      flex-direction: column;
      padding: 30px 0 30px;
      border-bottom: 1px solid rgba(130, 130, 130, 0.5);
    }

    & > *:last-child {
      padding-top: 60px;
      padding-bottom: 0;
      flex-direction: column;
      border-bottom: 0;

      & > figure {
        margin-right: 0;
        max-height: none;
      }

      & div > p {
        margin-bottom: 53px;
        width: 100%;
        line-height: 18.2px;
      }
    }

    &__logo {
      margin-bottom: 50px;
    }

    &__text {
      font-size: 14px;
      width: 100%;
      margin-bottom: 50px;
      color: $color-native-gray;
    }

    &__exhibition {
      margin-bottom: 16px;
    }

    &__img-name {
      font-size: 16px;
      margin-bottom: 30px;
    }

    &__exhibition-img {
      max-width: none;
      max-height: none;
    }
  }
}
