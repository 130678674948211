@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/SFProDisplay-Bold.woff2"),
  url("../assets/fonts/SFProDisplay-Bold.woff");
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../assets/fonts/SFProDisplay-Semibold.woff"),
  url("../assets/fonts/SFProDisplay-Semibold.woff2");
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/SFProDisplay-Medium.woff2"),
  url("../assets/fonts/SFProDisplay-Medium.woff");
}


@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/SFProDisplay-Regular.woff2"),
  url("../assets/fonts/SFProDisplay-Regular.woff");
}


@font-face {
  font-family: 'SFUIDisplay';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/SFUIDisplay-Bold.woff2"),
  url("../assets/fonts/SFUIDisplay-Bold.woff");
}


@font-face {
  font-family: 'SFUIDisplay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/SFUIDisplay-Regular.woff2"),
  url("../assets/fonts/SFUIDisplay-Regular.woff");
}
