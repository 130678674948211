.home-form {
    padding: 100px 0;
    &__wrapper {
        margin-top: 135px;
        background-color: #262626;
        border-radius: 50px;
        display: flex;
        position: relative;
        padding: 60px 75px;
        justify-content: space-between;
    }
    &__box {
        &>*:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        @include SFProFont(55px, 700);
        line-height: 60.5px;
        margin-bottom: 20px;
        margin-right: 40px;
    }
    &__img {
        max-width: 642px;
        max-height: 315px;
        width: 642px;
        height: 315px;
        position: absolute;
        z-index: 1;
        top: -135px;
        right: 31px;
    }
    &__blur {
        position: absolute;
        width: 80%;
        height: 116px;
        border-radius: 50%;
        @include positionAllCenter(50%, 50%);
        top: 60%;
        background: #F9F9F9;
        opacity: 0.5;
        filter: blur(58px);
        z-index: -1;
    }
    &__data {
        position: relative;
        width: 422px;
        height: 50px;
    }
    &__input {
        width: 100%;
        height: 100%;
        border: 1px solid #BC8762;
        border-radius: 50px;
        padding: 13px 0 13px 22px;
        background-color: #262626;
        color: $color-gray;
        font-size: 18px;
        outline: 0;
    }
    &__button {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        background-color: $main-color;
        position: absolute;
        right: 6px;
        top: 5px;
        cursor: pointer;
        transition: .2s all linear;
        &:hover {
            background-color: #CE9E7C;
        }
        &:disabled {
            //background-color: $color-gray;
        }
    }
    &__arrow {
        position: relative;
        width: 100%;
        height: 100%;
        &>svg {
            position: absolute;
            top: 13px;
            right: 13px;
        }
    }
    &__subtitle {
        @include SFProFont(18px, 400);
        line-height: 24px;
        margin-bottom: 40px;
    }
    &__text {
        display: flex;
        margin-bottom: 20px;
    }
    &__text-icon {
        width: 35px;
        height: 29px;
        margin-right: 15px;
    }
    &__text-name {
        @include SFProFont(18px, 400);
        line-height: 24px;
    }
}

.home-form__info {
    width: 100%;
    & .video {
        cursor: pointer;
        background: url(../../../../public/image/TestDrive/video.png) center no-repeat;
        background-size: cover;
        max-width: 552px;
        height: 100%;
        max-height: 340px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        & span {
            display: block;
            background: url(../../../../public/image/TestDrive/play.svg) center no-repeat;
            width: 72px;
            height: 72px;
            transition: all 100ms ease-in-out;
        }
        &:hover span {
            transform: scale(1.2);
        }
    }
}

@media (max-width: 1180px) {
    .home-form {
        &__wrapper {
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-around;
        }
        &__img {
            top: -140px;
            right: 25px;
        }
    }
}

@media (max-width: 920px) {
    .home-form {
        padding: 80px 0;
        &__wrapper {
            margin-top: 86px;
            padding: 40px 50px;
            border-radius: 20px;
            flex-direction: column;
        }
        &__send {
            display: grid;
            grid-template-columns: 1fr 280px;
            margin-bottom: 30px;
        }
        &__title {
            font-size: 32px;
            line-height: 38.4px;
        }
        &__info {}
        &__text {
            margin-bottom: 15px;
            align-items: center;
        }
        &__text-name {
            font-size: 16px;
            line-height: 20px;
            max-width: 200px;
        }
        &__data {
            grid-area: 2 / 2 / 3 / 3;
            width: 280px;
        }
        &__subtitle {
            padding-top: 0;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 17px;
            &>br {
                display: none;
            }
            &>.home-form__br768 {
                display: block;
            }
        }
        &__img {
            width: 370px;
            height: 201px;
            right: auto;
            left: 47px;
            top: -100px;
        }
        &__blur {
            height: 77px;
            filter: blur(32px);
        }
    }
    .home-form__info .video {
        margin: 0;
        max-width: 100%;
        height: 344px;
    }
}

@media (max-width: 764px) {
    .home-form {
        &__wrapper {
            padding-left: 24px;
            justify-content: space-between;
        }
        &__info {
            order: 1;
            margin-bottom: 30px;
            & .video {
                height: 177px;
                border-radius: 10px;
                background-position-y: top;
                & span {
                    width: 50px;
                    height: 50px;
                    background-size: cover;
                }
            }
        }
        &__subtitle {
            max-width: 303px;
        }
        &__data {
            width: 262px;
            order: 3;
        }
        &__img {
            left: 27px;
        }
    }
    .home-form__send {
        display: block;
        order: 2;
    }
}

@media (max-width: 710px) {
    .home-form {
        padding: 0 0 60px 0;
        &__img {
            width: 100%;
            top: -60px;
            left: 50%;
            transform: translateX(-50%);
            filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
            &>img {
                display: block;
                width: 255px;
                height: 197px;
                margin: 0 auto;
            }
        }
        &__wrapper {
            margin-top: 87px;
            padding: 24px 23px 32px;
            flex-direction: column;
        }
        &__title {
            margin-bottom: 16px;
            margin-right: 0;
        }
        &__info {
            margin-left: 0;
            margin-bottom: 30px;
        }
        &__box {
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            &>*:last-child {
                margin-bottom: 14px;
            }
        }
        &__data {
            width: 100%;
        }
        &__blur {
            width: 100%;
            height: 60px;
            background: #F9F9F9;
            filter: blur(50px);
        }
        &__subtitle {
            margin: 0;
            &>.home-form__br768 {
                display: none;
            }
        }
    }
}

@media (max-width: 400px) {
    .home-form {
        &__blur {
            top: 70%;
            height: 110px;
            filter: blur(60px);
        }
        &__br375 {
            display: block;
        }
    }
}

@media (max-width: 367px) {
    .home-form {
        &__subtitle {
            display: block;
            &>.home-form__br375 {
                display: inline;
            }
        }
    }
}