.background-home-technology {
  background: url("../../../../public/image/Home/HomeTechnology/HomeTechnologyBackground.jpg");
  background-size: cover;
  overflow: hidden;

  & > div {
    overflow: visible;
  }

  //&--2 {
  //  background: url("../../../../public/image/Home/HomeTechnology/HomeTchnologySlide2.jpg");
  //  background-size: 100% 100%;
  //  background-repeat: no-repeat;
  //}
}


.home-technology {

  &__item {
    display: flex;
    position: relative;
    //justify-content: center;
  }


  &__text {
    padding-top: 307px;
    flex-shrink: 0;

    &--1 {
      padding-bottom: 377px;
    }

    &--2 {
      padding-bottom: 471px;
    }

    &--3 {
      padding-bottom: 494px;
    }
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin-bottom: 40px;
  }

  &__subtitle {
    @include SFProFont(22px, 700);
    line-height: 28px;
  }

  &__img {
    position: absolute;

    &--section1 {
      right: 0;
      top: 52%;
      transform: translateY(-52%);
    }

    &--section2 {
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
    }

    &--section3 {
      right: -70px;
      top: 54%;
      transform: translateY(-54%);
    }
  }

  &__innerBr {
    display: block;
    height: 10px;
  }
}

@media (max-width: 1500px) {
  .home-technology {
    &__img {
      &--section1 {
        right: -20px;
      }
      &--section2 {
        right: -40px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .home-technology {
    &__title {
      font-size: 50px;
      line-height: 55px;
    }

    &__subtitle {
      font-size: 20px;
      line-height: 24px;
    }


    &__img {
      position: absolute;

      &--section1 {
        right: -150px;
      }

      &--section2 {
        right: -40px;
        width: 480px;
        height: 685px;
      }

      &--section3 {
        right: -90px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .background-home-technology {
    background: linear-gradient(4.49deg, #111111 7.61%, rgba(9, 9, 9, 0.554623) 15.87%, rgba(0, 0, 0, 0) 34.74%), linear-gradient(176.52deg, #111111 2.81%, rgba(0, 0, 0, 0) 30.11%), linear-gradient(96.88deg, #111111 -10.23%, rgba(17, 17, 17, 0) 26.17%), linear-gradient(0deg, rgba(4, 4, 4, 0.2), rgba(4, 4, 4, 0.2)), url("../../../../public/image/Home/HomeTechnology/HomeTechnologyBackground-768.jpg");
    background-position: top left;
    background-size: 110% 100%;
    background-repeat: no-repeat;

    //&--2 {
    //  background: url("../../../../public/image/Home/HomeTechnology/HomeTchnologySlide2-768.jpg");
    //  background-size: 100% 100%;
    //  background-repeat: no-repeat;
    //}
  }

  .home-technology {

    &__br768 {
      display: block;
    }

    &__item {
      flex-direction: column;
    }
    &__text {
      &--1 {
        padding: 62px 0 0 0;
      }
      &--2 {
        padding: 35px 0 0 0;
        margin-bottom: 27px;
      }
      &--3 {
        padding: 30px 0 0 0;
        margin-bottom: 22px;
      }
    }
    &__img {
      position: static;
      transform: none;
      width: auto;
      height: auto;
      margin: 0 auto 10px;

      & > img {
        object-fit: cover;
      }

      &--section1 {
        margin-top: 60px;
        & > img {
          max-width: 738px;
          max-height: 616px;
        }
      }
      &--section2 {
        max-width: 470px;
        max-height: 634px;
        width: 470px;
        height: 634px;
        margin-bottom: 43px;

        & > img {
          max-width: 470px;
          max-height: 634px;
        }
      }
      &--section3 {
        max-width: 698px;
        max-height: 674px;
        transform: rotate(-11deg);
        margin: 0 0 92px;
      }
    }
    &__title {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 0;
      &--1 {
        position: absolute;
      }
    }
    &__subtitle {
      font-size: 22px;
      line-height: 28px;
      &--1 {
        padding-bottom: 44px;
      }
      &--2 {
        padding-bottom: 35px;
        max-width: 641px;
        & > br {
          display: none;
        }
        & > .home-technology__hide375 {
          & > br {
            display: none;
          }
        }
      }
      &--3 {
        max-width: 626px;
        padding-bottom: 44px;

        & > br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home-technology {
    display: flex;
    flex-direction: column;

    &__text {
      &--1 {
        padding-top: 17px;
        margin-bottom: 29px;
      }
      &--2 {
        padding-top: 15px;
        margin-bottom: 6px;
      }
      &--3 {
        padding-top: 28px;
        margin-bottom: 35px;
      }
    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      text-align: center;
      &--1 {
        position: static;
      }
      &--2 {
        max-width: 359px;
        margin: 0 auto;
      }
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      //max-width: 349px;
      & > br {
        display: none;
      }
      &--1 {
        padding-bottom: 20px;
        max-width: 349px;
      }
      &--2 {
        padding-bottom: 40px;
      }
      &--3 {
        padding-bottom: 39px;
      }
    }
    &__br768 {
      display: none;
    }

    &__img {
      &--section1 {
        margin-top: 0;
        margin-bottom: 0;
      }
      &--section2 {
        margin-bottom: 10px;
        width: auto;
        height: auto;
        //max-height: 423px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
      &--section3 {
        margin-bottom: 10px;
        & > img {
          object-fit: cover;
        }
      }
    }

    &__br375 {
      display: block;
    }

    &__innerBr {
      height: 20px;
    }

    &__hide375 {
      display: none;
    }
  }

  .background-home-technology {
    &--1 {
      order: 1;
    }
    &--2 {
      order: 3;
    }
    &--3 {
      order: 2;
    }
  }
}

@media (max-width: 380px) {
  .home-technology {
    &__title {
      &--2 {
        position: absolute;
        width: 360px;
        left: -2%;
      }
    }

    &__img {
      &--section2 {
        margin-top: 112px;
        max-width: 310px;
        max-height: 423px;
        margin-bottom: 20px;
      }
      &--section3 {
        margin-bottom: 5px;
      }
    }
  }
}


@media (max-width: 374px) {
  .home-technology {
    &__br375 {
      display: none;
    }
  }
}


@media (max-width: 370px) {
  .home-technology {
    &__title {
      font-size:  26px;
      line-height: 28px;
      &--2 {
        position: static;
        width: auto;
        max-width: 300px;
      }
    }

    &__img {
      &--section2 {
        margin-top: 0;
      }
    }
  }
}

