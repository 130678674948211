.background-home-dreams {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 23.78%), url("../../../../public/image/Home/HomeForm.jpg");
  //@include backgroundProperties(red);
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.home-dreams {
  &__wrapper {
    padding-top: 87px;
    height: 728px;

    & > form {
      & > .form__policy {
        & > .form__text {
          font-size: 14px;
        }
      }
    }
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin: 0 0 50px 0;
  }

  &__data {
    max-width: 338px;
  }

  &__inputs {
    margin-bottom: 10px;
  }

  &__input {
    height: 50px;
    margin-bottom: 10px;
    font-size: 18px;
  }

  &__policy {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  &__check {
    width: 24px;
    height: 24px;
  }

  &__text {
    @include SFProFont(14px, 400);
    padding-top: 5px;
    line-height: 17px;
    margin-left: 9px;
    color: $color-gray;
  }


  &__submit {
    height: 50px;
  }
}

@media (max-width: 1024px) {
  .background-home-dreams {
    background: linear-gradient(180deg, #111111 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(357.46deg, #111111 -0.06%, rgba(0, 0, 0, 0) 34.82%),
    url("../../../../public/image/Home/HomeForm.jpg");
    @include backgroundProperties(#111111);
    background-size: auto 81%;
    background-repeat: no-repeat;
    //background-position: bottom right -450px;
  }

  .home-dreams {
    &__title {
      font-size: 45px;
      line-height: 49.5px;
      margin-bottom: 37px;
    }

    &__wrapper {
      padding: 70px 0 0 34px;

      & > form {
        max-width: 338px;

      }

    }
  }
}

@media (max-width: 600px) {
  .background-home-dreams {
    background: url("../../../../public/image/Home/HomeForm.jpg");
    @include backgroundProperties(#111111);
    background-size: auto 67%;
    background-repeat: no-repeat;
    background-position: right -245px top;
    position: relative;
    &:after {
      @include backgraundAfter();
      background: linear-gradient(175.28deg, #111111 3.82%, rgba(17, 17, 17, 0) 48.36%), linear-gradient(360deg, #111111 0%, rgba(17, 17, 17, 0) 41.12%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
      z-index: 1;
      height: 400px;
    }
  }
  .home-dreams {
    &__wrapper {
      padding: 38px 0 0;
      height: 589px;
      position: relative;
      z-index: 2;
      & > form {
        max-width: 250px;
        margin: 0 auto;
      }
    }
    &__title {
      text-align: center;
      font-size: 32px;
      line-height: 38.4px;
      max-width: 269px;
      margin: 0 auto 184px;
    }
    &__text {
      font-size: 12px;
      line-height: 12px;
      margin-left: 3px;
      padding-top: 7px;
      & > br {
        display: none;
      }
    }

    &__check {
      width: 22px;
      height: 22px;
    }

    &__input {
      height: 42px;
      margin-bottom: 10px;
      font-size: 16px;
    }

    &__submit {
      height: 42px;
    }
  }
}
