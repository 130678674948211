.home-comfort {
  &__wrapper {
    padding-top: 100px;
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin-bottom: 60px;
    text-align: center;
  }

  &__item {
    box-shadow: inset 0 1px 30px rgba(219, 215, 215, 0.05);
    border-radius: 50px;
    position: relative;
    &--1 {
      background: linear-gradient(282.65deg, #1B1919 -29.28%, rgba(37, 37, 37, 0) 158.08%) #191818;
      background-image: url("../../../../public/image/Home/HomeComfort/HomeComfortItem1.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      margin-bottom: 50px;
    }

    &--2 {
      background: linear-gradient(268.43deg, #1B1919 83.32%, rgba(37, 37, 37, 0) 123.67%) #191818;
      background-image: url("../../../../public/image/Home/HomeComfort/HomeComfortItem2.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      margin-right: 30px;
      height: 313px;
    }

    &--3 {
      background: linear-gradient(282.65deg, #1B1919 -29.28%, rgba(37, 37, 37, 0) 158.08%) #191818;
      background-image: url("../../../../public/image/Home/HomeComfort/HomeComfortItem3.jpg");
      background-repeat: no-repeat;
      background-position: right bottom;
      height: 313px;
    }
  }

  &__blur {
    display: none;
  }

  &__item-flex {
    display: flex;
    & > * {
      width: 50%;
    }
  }

  &__item-wrapper {
    padding: 101px 0 102px 60px;
    &--important {
      padding: 113px 0 112px 106px
    }
  }

  &__text {
    &--important {
      margin-bottom: 50px;
    }
  }

  &__name {
    @include SFProFont(22px, 700);
    line-height: 28px;
    margin-bottom: 10px;
  }
  &__desc {
    max-width: 376px;
    @include SFProFont(18px, 400);
    line-height: 24px;
  }

}


@media (max-width: 1024px) {
  .home-comfort {
    &__item {
      &--2 {
        margin-right: 20px;
      }
    }

    &__item-wrapper {
      padding-left: 30px;
      &--important {
       padding-left: 56px;
      }
    }
  }
}


@media (max-width: 920px) {
  .home-comfort {
    &__title {
      font-size: 45px;
      line-height: 49.5px;
      margin-bottom: 40px;
    }

    &__wrapper {
      padding-top: 70px;
    }

    &__item-flex {
      flex-direction: column;
      & > * {
        width: 100%;
     }
    }
    &__item {
      &--1 {
        background-position: right -40px bottom;
      }
      &--2 {
        margin-right: 0;
        margin-bottom: 20px;
        background-position: right -20px bottom;
      }
      &--3 {
        background-position: right -20px bottom;
      }
    }
    &__item-wrapper {
      padding: 101px 0 102px 66px;
      &--important {
        padding: 83px 0 525px 66px;
      }
    }
  }
}

@media (max-width: 600px) {
  .home-comfort {
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 27px;
    }
    &__wrapper {
      padding-top: 27px;
    }

    &__item-wrapper {
      padding: 54px 0 55px 25px;
      &--important {
        padding: 30px 0 224px 25px;
      }
    }


    &__item {
      box-shadow: inset 0px 1px 50px rgba(219, 215, 215, 0.1);
      background: none;
      border-radius: 20px;
      height: auto;
      position: relative;
      overflow: hidden;
      &--1 {
        margin-bottom: 20px;
        background: linear-gradient(130.51deg, #1C1A1A 58.59%, rgba(17, 17, 17, 0) 103.7%);
        background-repeat:  no-repeat;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        background-size: auto 100%;
        background-position: bottom right;


        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: url("../../../../public/image/Home/HomeComfort/HomeComfortItem1.jpg");
          background-size: 347px 264px;
          background-repeat: no-repeat;
          background-position: right -20px bottom;
        }
      }
      &--2 {
        margin-right: 0;
        margin-bottom: 20px;
        background: linear-gradient(118.31deg, #1B1919 17.81%, rgba(17, 17, 17, 0) 111.42%) #191818;

        & > div {
          max-width: 200px;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          z-index: 0;
          background: linear-gradient(129.64deg, #1C1B1B 12.62%, rgba(0, 0, 0, 0) 72.65%), linear-gradient(84.97deg, #161616 34.3%, rgba(0, 0, 0, 0) 72.15%), url("../../../../public/image/Home/HomeComfort/HomeComfortItem4.jpg") #191818;
          background-position: right 0 top;
          background-repeat: no-repeat;
          box-shadow: inset 0px 1px 50px rgba(219, 215, 215, 0.1);
        }

      }
      &--3 {
        & > div {
          padding: 64px 0 65px 25px;
          max-width: 240px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(117.17deg, #191818 23.79%, rgba(0, 0, 0, 0) 86.5%), linear-gradient(68.76deg, #161515 20.97%, rgba(25, 24, 24, 0.29) 72.56%),url("../../../../public/image/Home/HomeComfort/HomeComfortItem3.jpg") #191818;
          background-position: right -30px  top -20px;
          background-size: 266px 253px;
          background-repeat: no-repeat;
          border-radius: 20px;
          box-shadow: inset 0 1px 50px rgba(219, 215, 215, 0.1);
        }
      }
    }

    &__text {
      position: relative;
      z-index: 1;
      &--important {
        margin-bottom: 33px;
      }
    }

    &__name {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
      color: $main-color;
    }

    &__desc {
      font-size: 16px;
      line-height: 20px;
      max-width: 299px;

      & > br {
        display: none;
      }
    }

    &__br375 {
      display: block;
    }
  }
}
