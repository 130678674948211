.change-content {
  max-height: 800px;
  & > .swiper {
    width: 100%;
    height: 100%;

    & > div > .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: blue;
      max-height: 800px;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }

  & > .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}
