.home-video {
  position: relative;
  overflow: hidden;
  height: 823px;

  &__wrapper {
    width: 100%;
    height: 823px;
    object-fit: cover;
  }
  &__icon {
    width: 110px;
    height: 110px;
    position: absolute;
    @include positionAllCenter(50%, 50%);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
  }
}

@media (max-width: 1024px) {
  .home-video {
    height: 1024px;
    &__wrapper {
      height: 1024px;
    }

    &__icon {
      width: 70px;
      height: 65px;
    }
  }
}

@media (max-width: 600px) {
  .home-video {
    height: 667px;
    &__wrapper {
      height: 667px;
    }
    &__icon {
      width: 55px;
      height: 60px;
    }
  }
}
