.home-complex {


  &__wrapper {
    padding: 124px 0 217px ;
  }

  &__title {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    color: #000000;
    text-align: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 99px;
  }

  &__text {
    margin-right: 40px;
    flex-shrink: 0;
  }


  &__number {
    @include SFProFont(22px, 700);
    line-height: 28px;
    margin-bottom: 24px;
    color: $color-gray
  }
  &__name {
    @include SFProFont(55px, 700);
    line-height: 60.5px;
    margin-bottom: 40px;
    color: #000000;
  }
  &__subtitle {
    @include SFProFont(22px, 700);
    line-height: 28px;
    color: $color-gray;
    max-width: 500px;
  }

  &__img {
    max-width: 518px;
    max-height: 517px;
    & > img {
      border-radius: 50%;
    }
  }
}

@media (max-width: 1024px) {
  .home-complex {
    text-align: center;


    &__wrapper {
      padding: 39px 0 62px;
    }

    &__title {
      font-size: 45px;
      line-height: 55px;
    }

    &__text {
      margin-right: 0;
    }

    &__item {
      margin-top: 40px;
      flex-direction: column-reverse;
    }

    &__img {
      max-width: 440px;
      max-height: 440px;
      margin-bottom: 20px;
    }

    &__name {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 20px;
    }

    &__subtitle {
      max-width: 511px;
    }

    &__number {
      margin-bottom: 15px;
    }

    &__br768 {
      display: block;
    }
  }
}


@media (max-width: 600px) {
  .home-complex {
    &__wrapper {
      padding: 27px 0 19px;
    }
    &__item {
      margin-top: 19px;
    }
    &__title {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 19px;
    }
    &__number {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    &__name {
      font-size: 32px;
      line-height: 38.4px;
      margin-bottom: 7px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      max-width: 350px;
    }
    &__img {
      max-width: 260px;
      max-height: 260px;
    }
    &__br768 {
      display: none;
    }
    &__br375 {
      display: block;
    }
  }
}
